import { firebaseConfig } from "@/firebase/config";

export const UICN_LABEL = {
  VU: "Vulnérable",
  EN: "En danger",
  CR: "En danger critique",
};

export const RARENESS_LABEL = {
  VU: "Commun",
  EN: "Occasionnel",
  CR: "Rare",
};

export const UICN_COLOR = {
  VU: "#d69f1d",
  EN: "#f37a0f",
  CR: "#f60503",
};

export const LEVEL_MULTIPLIER = {
  CR: 6,
  EN: 3,
  VU: 1,
};

export const ANIMAX_STRENGTH = {
  CR: 3,
  EN: 2,
  VU: 1,
};

export const MODULES_COLOR = {
  collection: "gold",
  habitats: "red",
  profil: "purple",
};

export const playSound = (audioObj, isSoundActivatedInGame) => {
  if (audioObj.paused && isSoundActivatedInGame) {
    try {
      audioObj.play();
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  } else {
    audioObj.currentTime = 0;
  }
};

export const initPlayer = () => {
  return {
    animals: {},
    habitats: {},
    currency: 1000,
  };
};

export const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const addLeadingZero = (number) => {
    return number < 10 ? `0${number}` : number;
  };
  return `${addLeadingZero(date.getDate())}/${addLeadingZero(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
};

export const calculateDamage = (animaxRareness, souls) => {
  return souls * LEVEL_MULTIPLIER[animaxRareness];
};

export const buildImgUrl = (storagePath) => {
  const bucketPath = firebaseConfig.storageBucket;
  const host =
    import.meta.env.MODE === "development"
      ? "http://localhost:9199"
      : "https://firebasestorage.googleapis.com";
  return `${host}/v0/b/${bucketPath}/o/${encodeURIComponent(storagePath)}?alt=media`;
};

export const numberFormatter = (value) => {
  return value < 10 ? `0${value}` : value;
};

export const buildAvatarUrl = (id) => {
  if (!id) {
    return "/src/assets/placeholder-avatar.png";
  } else {
    return buildImgUrl(`Avatars/${id}.png`);
  }
};
