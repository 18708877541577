import { RouterView } from "vue-router";

const routes = [
  {
    path: "profile",
    name: "profile-route",
    component: RouterView,
    redirect: { name: "profile-info-route" },
    children: [
      {
        path: "",
        name: "profile-info-route",
        component: () => import("./Profile.vue"),
        meta: { title: "Profile" },
      },
      {
        path: "avatar",
        name: "avatar-route",
        component: () => import("./AvatarSelector.vue"),
        meta: { title: "Avatar" },
      },
    ],
  },
];

export default routes;
