const routes = [
  {
    path: "scan",
    component: () => import("./Scan.vue"),
    children: [
      {
        path: "",
        name: "scanner-route",
        component: () => import("./views/Scanner.vue"),
        meta: { title: "Scan" },
      },
      {
        path: ":id(.*)",
        props: true,
        name: "scan-result-route",
        component: () => import("./views/ScanResult.vue"),
        meta: { title: "Scan result - " },
      },
      {
        path: "rewards/:id(.*)",
        props: true,
        name: "scan-rewards",
        component: () => import("./views/Rewards.vue"),
        meta: { title: "Scan rewards - " },
      },
    ],
  },
];

export default routes;
