import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "./index";

export const listAllFiles = (path) => {
  const listRef = ref(storage, path);

  return listAll(listRef);
};

export const getAllFiles = (path, callback) => {
  listAllFiles(path)
    .then((res) => {
      res.items.forEach((itemRef) => {
        getDownloadURL(itemRef).then((url) => callback(url));
      });
    })
    .catch((error) => {
      console.error(error);
    });
};
