const routes = {
  path: "/",
  name: "auth-route",
  component: () => import("./Auth.vue"),
  children: [
    {
      path: "login",
      name: "login-route",
      component: () => import("./views/Login.vue"),
      meta: { title: "Login" },
    },
    {
      path: "register",
      name: "register-route",
      component: () => import("./views/Register.vue"),
      meta: { title: "Register" },
    },
  ],
};

export default routes;
