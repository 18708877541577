const getDefaultState = () => ({});

const store = {
  state: () => getDefaultState(),
  mutations: {},
  actions: {},
  getters: {},
};

export default store;
