import { RouterView } from "vue-router";
import scanRoutes from "@scan/router";

const routes = [
  {
    path: "animax",
    name: "collection-route",
    component: RouterView,
    children: [
      {
        path: "",
        name: "home-route",
        component: () => import("./views/Collection.vue"),
        meta: { title: "My collection" },
        children: scanRoutes,
      },
      {
        path: ":id",
        name: "animax-card-route",
        component: () => import("./views/AnimaxCard.vue"),
        props: true,
        meta: { title: "Animax Nº" },
      },
    ],
  },
  {
    path: "/collection/:id",
    name: "friend-collection-route",
    component: () => import("./views/FriendCollection.vue"),
    props: true,
    meta: { title: "Friend Collection " },
  },
];

export default routes;
