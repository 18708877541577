import {
  COLUMNS,
  ROWS,
  INITIAL_POLLUTED_ROWS,
  TYPE_EMPTY,
  TYPE_TOXIBEAST,
  TYPE_PLANT,
  TYPE_CHEST,
  TYPE_POLLUTED,
  MAX_LEVEL,
  getRandomSquare,
  getRandomNumber,
  selectRandomPositions,
  getIndex,
  getPollution,
} from "@mergeGame/utils";
import { listAllFiles } from "@/firebase/storageUtils";
import { getDownloadURL } from "firebase/storage";
import { buildImgUrl } from "@/utils";

const getDefaultState = () => ({
  map: [],
  highestLevel: 1,
  currentPosition: null,
  plantAssets: {},
  wasteAssets: null,
  pollutedCounter: 0,
  toxibeastCounter: 0,
  habitatID: null,
  playerLife: 3,
  biomeIconUrl: null,
});

const determinePollutedAssetLevel = (position) => {
  if (position <= 13) {
    return 3;
  } else if (position >= 14 && position <= 27) {
    return 2;
  }
  return 1;
};

const store = {
  state: () => getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    UPDATE_SQUARE(state, square) {
      state.map[square.position] = { ...state.map[square.position], ...square };
    },
    UPDATE_POLLUTED_COUNTER(state, increment) {
      state.pollutedCounter += increment;
    },
    UPDATE_TOXIBEAST_COUNTER(state, increment) {
      state.toxibeastCounter += increment;
    },
    SET_HIGHEST_LEVEL(state, level) {
      state.highestLevel = Math.max(state.highestLevel, level);
    },
    SET_HABITAT_ID(state, id) {
      state.habitatID = id;
    },
    SET_CURRENT_ZONE(state, zone) {
      state.zone = zone;
    },
    GENERATE_MAP(state, zone) {
      state.map = [];
      const toxibeastsPosition = selectRandomPositions(COLUMNS * INITIAL_POLLUTED_ROWS, zone);
      let pollutedSquaresPosition = [];
      for (let i = 0; i < ROWS; i++) {
        let amount = 0;
        switch (i) {
          case INITIAL_POLLUTED_ROWS:
            amount = 5;
            break;
          case INITIAL_POLLUTED_ROWS + 1:
            amount = 3;
            break;
          case INITIAL_POLLUTED_ROWS + 2:
            amount = 1;
            break;
          default:
            pollutedSquaresPosition = [];
        }
        pollutedSquaresPosition = selectRandomPositions(COLUMNS, amount, i * COLUMNS);

        for (let j = 0; j < COLUMNS; j++) {
          const newSquare = {
            position: getIndex(i, j),
          };
          if (
            i <= INITIAL_POLLUTED_ROWS - 1 ||
            (pollutedSquaresPosition.length && pollutedSquaresPosition.includes(getIndex(i, j)))
          ) {
            newSquare.type = TYPE_POLLUTED;
            newSquare.polluted = getPollution(i, zone);
            newSquare.initialPolluted = newSquare.polluted;
            newSquare.level = determinePollutedAssetLevel(newSquare.position);
            state.pollutedCounter += 1;

            if (toxibeastsPosition.includes(getIndex(i, j))) {
              newSquare.toxibeast = true;
              state.toxibeastCounter += 1;
            }
          } else {
            newSquare.type = TYPE_EMPTY;
          }
          state.map.push(newSquare);
        }
        pollutedSquaresPosition = [];
      }
    },
    UPDATE_CURRENT_POSITION(state, position) {
      state.currentPosition = position;
    },
    UPDATE_PLANT_ASSETS(state, { level, asset }) {
      state.plantAssets[level] = asset;
    },
    SET_WASTE_ASSETS(state, assets) {
      state.wasteAssets = assets;
    },
    DECREASE_PLAYER_LIFE(state) {
      state.playerLife = Math.max(0, state.playerLife - 1);
    },
    RESET_PLAYER_LIFE(state) {
      state.playerLife = 3;
    },
  },
  actions: {
    onPlantMove({ state, dispatch }, positions) {
      const { initialPosition, destinationPosition } = positions;
      const initialItem = state.map[initialPosition];
      const destinationItem = state.map[destinationPosition];

      if (destinationItem.type === TYPE_EMPTY) {
        dispatch("movePlant", { initialPosition, destinationPosition });
      } else if (initialItem.level === destinationItem.level) {
        dispatch("mergePlants", { initialPosition, destinationItem });
      }
    },
    movePlant({ state, commit }, { initialPosition, destinationPosition }) {
      commit("UPDATE_SQUARE", {
        ...state.map[initialPosition],
        position: destinationPosition,
      });
      commit("UPDATE_SQUARE", {
        type: TYPE_EMPTY,
        position: initialPosition,
      });
    },
    mergePlants({ commit, dispatch }, { initialPosition, destinationItem }) {
      if (destinationItem.level + 1 < MAX_LEVEL) {
        dispatch("updateHighestLevel", destinationItem.level + 1);
        commit("UPDATE_SQUARE", {
          position: destinationItem.position,
          level: destinationItem.level + 1,
        });
        commit("UPDATE_SQUARE", {
          type: TYPE_EMPTY,
          position: initialPosition,
        });
      }
    },
    healSquare({ state, getters, commit }, position) {
      const item = state.map[position];
      const pollutedSquare = getters.getClosestPollutedSquare;
      if (pollutedSquare) {
        pollutedSquare.polluted -= Math.pow(2, item.level - 1);
        if (pollutedSquare.polluted <= 0) {
          commit("UPDATE_POLLUTED_COUNTER", -1);
          commit("UPDATE_SQUARE", {
            polluted: null,
            position: pollutedSquare.position,
            type: pollutedSquare.toxibeast ? TYPE_TOXIBEAST : TYPE_EMPTY,
          });
        }
      }
    },
    dropItem({ commit, getters, dispatch }) {
      let position = getRandomSquare();
      while (!getters.isSquareEmpty(position)) {
        position = getRandomSquare();
      }
      const newItem = { position };
      if (getRandomNumber() === 0) {
        newItem.type = TYPE_CHEST;
      } else {
        newItem.type = TYPE_PLANT;
        newItem.level = 1;
      }
      commit("UPDATE_SQUARE", newItem);
      dispatch("getPlantAssetUrl", 2);
    },
    highlightSquare({ state, commit }, positions) {
      const initialItem = state.map[positions.initialPosition];
      const destinationItem = state.map[positions.destinationPosition];

      if (positions.destinationPosition === state.currentPosition) {
        return;
      }

      if (
        destinationItem.type === TYPE_EMPTY ||
        (destinationItem.type === TYPE_PLANT && initialItem.level === destinationItem.level)
      ) {
        commit("UPDATE_CURRENT_POSITION", positions.destinationPosition);
      } else {
        commit("UPDATE_CURRENT_POSITION", null);
      }
    },

    openChest({ state, commit, dispatch }, position) {
      let maxSurpriseLevel = state.highestLevel - 3;
      let level = Math.floor(Math.random() * state.highestLevel - 3);
      if (level <= 0) {
        level = 1;
      }
      let i = 1;
      let counterLevel = 1;
      const newItem = { position, type: TYPE_PLANT };
      let timer = setInterval(() => {
        if (i === 15) {
          newItem.level = level;
          commit("UPDATE_SQUARE", newItem);
          dispatch("updateHighestLevel", level);
          clearInterval(timer);
        } else {
          if (counterLevel >= maxSurpriseLevel) {
            counterLevel = 1;
          }
          newItem.level = counterLevel;
          commit("UPDATE_SQUARE", newItem);
          i++;
          counterLevel++;
        }
      }, 50);
    },

    getPlantAssetUrl({ commit, state }, level) {
      if (!state.plantAssets[level]) {
        const url = buildImgUrl(`Habitats/${state.habitatID}/plants/${level}.png`);
        commit("UPDATE_PLANT_ASSETS", { level, asset: url });
      }
    },

    async getWasteAssetUrl({ commit, state }) {
      const urls = {};
      const res = await listAllFiles(`Habitats/${state.habitatID}/wastes`);
      for (let i in res.items) {
        urls[res.items[i].name] = await getDownloadURL(res.items[i]);
      }
      commit("SET_WASTE_ASSETS", urls);
      return urls;
    },

    updateHighestLevel({ commit, dispatch }, level) {
      commit("SET_HIGHEST_LEVEL", level);
      dispatch("getPlantAssetUrl", level + 1);
    },
  },
  getters: {
    getClosestPollutedSquare(state) {
      for (let i = ROWS - 1; i >= 0; i--) {
        for (let j = 0; j < COLUMNS; j++) {
          if (state.map[getIndex(i, j)].type === TYPE_POLLUTED) {
            return state.map[getIndex(i, j)];
          }
        }
      }
      return false;
    },

    isMapFull(state, getters) {
      return state.map.findIndex((_, index) => getters.isSquareEmpty(index)) === -1;
    },

    isSquareEmpty: (state) => (index) => {
      return state.map[index]?.type === TYPE_EMPTY;
    },
  },
};

export default store;
