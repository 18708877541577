import { createRouter, createWebHistory } from "vue-router";
import animaxRoutes from "@animax/router";
import habitatsRoutes from "@habitats/router";
import authRoutes from "@authentication/router";
import gameRoutes from "@mergeGame/router";
import profileRoutes from "@profile/router";
import { auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";

const Default = () => import("@/views/Default.vue");

const routes = [
  {
    // The regex ensures that routes with tokens containing characters considered as special (e.g. # or ?) are considered part of the :id and match the route.
    path: "/tk/:id(.*)",
    name: "external-scan-route",
    component: () => import("@/views/ExternalScan.vue"),
    props: true,
    meta: { title: "Token " },
  },
  {
    path: "/externalcollection/:id/",
    name: "friend-external-collection-route",
    component: () => import("@/modules/animax/views/FriendCollection.vue"),
    props: true,
    meta: { title: "Friend Collection " },
  },
  {
    path: "/",
    name: "app-route",
    component: Default,
    redirect: { name: "home-route" },
    meta: { requiresAuth: true },
    children: [...animaxRoutes, ...habitatsRoutes, ...profileRoutes],
  },
  { ...authRoutes },
  ...gameRoutes,
  { path: "/:catchAll(.*)", redirect: { name: "home-route" } },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// Ensure that firebase auth is initialized when retrieving the current user
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const title = to.meta.title;
  const id = to.params.id;

  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }
  if (id) {
    document.title = title + id;
  }

  const user = await getCurrentUser();

  if (
    (to.path.startsWith("/tk/") || to.path.includes("/scan/")) &&
    (to.hash || Object.keys(to.query).length)
  ) {
    // Workaround for generated token IDs that start with '#' or contain '?'
    // as they are respectively interpreted as hash or query by Vue router.
    const id = to.fullPath.replace(to.path.startsWith("/tk/") ? "/tk/" : /^.+\/scan\//, "");
    next({ name: to.name, params: { id } });
  } else if (requiresAuth && !user) {
    next({ name: "login-route" });
  } else if ((to.name === "login-route" || to.name === "register-route") && user) {
    next(from ?? { name: "home-route" });
  } else {
    next();
  }
});

export default router;
