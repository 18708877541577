const routes = [
  {
    path: "/play/:habitatID/:externalTokenID",
    name: "external-merge-game-route",
    component: () => import("./Game.vue"),
    props: (route) => ({
      ...route.params,
      zone: 1,
      externalTokenID: route.params.externalTokenID,
    }),
    meta: { title: "External Game" },
  },
  {
    path: "/play/:habitatID",
    name: "merge-game-route",
    component: () => import("./Game.vue"),
    props: (route) => ({
      ...route.params,
      zone: parseInt(route.params.zone),
      animaxIds: route.params.animaxIds,
    }),
    beforeEnter: (to, _, next) => {
      if (!to.params.zone) {
        return next({
          name: "habitat-card-route",
          params: { id: to.params.habitatID },
        });
      }
      // reject the navigation
      return next();
    },
    meta: { title: "Game" },
  },
];

export default routes;
