import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "./index";

export const fetchPlayerInfo = async (playerId) => {
  const docRef = doc(db, "players", playerId);
  const docSnap = await getDoc(docRef);
  return {
    username: docSnap.data().username,
    avatarId: docSnap.data().avatarId,
  };
};

export const updatePlayer = (playerId, payload) => {
  const docRef = doc(db, "players", playerId);
  return updateDoc(docRef, payload);
};

export const fetchScannedTokens = async (fieldFilters = {}, order = {}) => {
  const { uid, aid, tid } = fieldFilters;
  const { date } = order;
  let scansQuery = query(collection(db, "scans"));
  if (uid) {
    scansQuery = query(scansQuery, where("uid", "==", uid));
  }
  if (tid) {
    scansQuery = query(scansQuery, where("tid", "==", tid));
  }
  if (aid) {
    scansQuery = query(scansQuery, where("aid", "==", aid));
  }
  if (date) {
    scansQuery = query(scansQuery, orderBy("date", date));
  }
  return await getDocs(scansQuery);
};
