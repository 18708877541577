import { createStore } from "vuex";
import { auth, db } from "@/firebase";
import router from "@/router";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import {
  collection,
  query,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  setDoc,
  where,
  Timestamp,
} from "firebase/firestore";
import animax from "@animax/store";
import authentication from "@authentication/store";
import habitats from "@habitats/store";
import mergeGame from "@mergeGame/store";
import scan from "@scan/store";
import { initPlayer } from "@/utils";

export default createStore({
  modules: {
    animax,
    authentication,
    habitatsModule: habitats,
    mergeGame,
    scan,
  },
  state: {
    currentUser: undefined,
    player: null,
    animals: [],
    habitats: [],
    isSoundActivatedInGame: true,
    isMenuOpen: false,
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    SET_PLAYER(state, player) {
      if (!player.tokens) {
        player.tokens = state.player?.tokens;
      }
      state.player = player;
    },
    SET_ANIMALS(state, animals) {
      state.animals = animals;
    },
    SET_HABITATS(state, habitats) {
      state.habitats = habitats;
    },
    SET_IS_MENU_OPEN(state, open) {
      state.isMenuOpen = open;
    },
  },
  actions: {
    async login(_, details) {
      const { email, password } = details;

      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        switch (error.code) {
          case "auth/user-not-found":
            alert("User not found");
            break;
          case "auth/wrong-password":
            alert("Wrong password");
            break;
          default:
            alert("Something went wrong");
        }

        return;
      }

      router.push({ name: "home-route" });
    },

    async register(_, details) {
      const { email, password, username } = details;
      let userCredential;

      try {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } catch (error) {
        switch (error.code) {
          case "auth/email-already-in-use":
            alert("Email already in use");
            break;
          case "auth/invalid-email":
            alert("Invalid email");
            break;
          case "auth/operation-not-allowed":
            alert("Operation not allowed");
            break;
          case "auth/weak-password":
            alert("Weak password");
            break;
          default:
            alert("Something went wrong");
        }

        return;
      }

      try {
        await updateProfile(userCredential.user, {
          displayName: username,
        });
      } catch (error) {
        alert("Something went wrong");
      }

      const docRef = doc(db, "players", userCredential.user.uid);
      await setDoc(docRef, {
        ...initPlayer(),
        username,
      });

      router.push({ name: "home-route" });
    },

    async logout({ commit }) {
      commit("SET_IS_MENU_OPEN", false);
      await signOut(auth);

      router.push({ name: "login-route" });
    },

    fetchCurrentUser({ commit }) {
      auth.onAuthStateChanged((user) => {
        commit("SET_CURRENT_USER", user);
      });
    },

    fetchPlayer({ commit, state }) {
      const docRef = doc(db, "players", state.currentUser.uid);
      return onSnapshot(docRef, async (doc) => {
        const player = doc.data();
        if (player) {
          commit("SET_PLAYER", player);
        }
      });
    },

    async fetchAnimals({ commit }) {
      const querySnapshot = await getDocs(collection(db, "animals"));
      const animals = [];
      querySnapshot.forEach((doc) => {
        animals.push({ ...doc.data(), id: doc.id });
      });
      animals.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      commit("SET_ANIMALS", animals);
    },

    async fetchHabitats({ commit }) {
      const querySnapshot = await getDocs(collection(db, "habitats"));
      const habitats = [];
      querySnapshot.forEach((doc) => {
        habitats.push({ ...doc.data(), id: doc.id });
      });
      commit("SET_HABITATS", habitats);
    },
    async fetchPlayerTokens({ commit, state }, userId) {
      const id = userId ?? state.currentUser.uid;
      const scansQuery = query(collection(db, "scans"), where("uid", "==", id));
      const scansSnapshot = await getDocs(scansQuery);
      const tokens = {};
      scansSnapshot.forEach((token) => {
        const { tid, date } = token.data();
        const firestoreDate = new Timestamp(date.seconds, date.nanoseconds);
        tokens[tid] = firestoreDate.toDate();
      });
      commit("SET_PLAYER", {...state.player, tokens});
    },

    async updatePlayerAvatar({ commit, state }, avatarId) {
      const playerRef = doc(db, "players", state.currentUser.uid);
      await setDoc(playerRef, { avatarId }, { merge: true });
    }
  },
  getters: {
    getAnimaxById: (state) => (id) => {
      return state.animals.find((animal) => animal.id === id) ?? {};
    },
    getAnimaxByEnvironments: (state) => (environments) => {
      const envToInclude = new Set(environments);
      return state.animals.filter((animax) => {
        const animaxEnv = new Set(animax.environments);
        return envToInclude.intersection(animaxEnv).size > 0;
      });
    },
    getAnimaxByHabitat: (state) => (habitatID) => {
      return state.animals.filter((animax) => animax.habitat.id === habitatID);
    },
    getHabitatById: (state) => (id) => {
      return state.habitats.find((habitat) => habitat.id === id) ?? {};
    },
    getPlayerTokens: (state) => {
      return state.player?.tokens ?? [];
    },
    getTotalAmountOfAnimals: (state) => {
      return state.animals?.length;
    },
    getPlayerScore: (state) => {
      return state.player ? Object.keys(state.player.animals).length : 0;
    },
    getPlayerAnimaxList: (state) => {
      return state.player?.animals ?? {};
    },
    getPlayerHabitatsList: (state) => {
      return state.player?.habitats ?? {};
    },
    getPlayerAnimax: (state) => (animaxId) => {
      return state.player?.animals[animaxId];
    },
    getPlayerSecretImages: (state) => (animaxId) => {
      return state.player?.animals[animaxId].secretImages ?? [];
    },
  },
});
