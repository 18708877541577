import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  getDoc,
} from "firebase/firestore";
import { db } from "@/firebase";
import { fetchPlayerInfo } from "@/firebase/playerUtils";

const getDefaultState = () => ({
  token: null,
  animax: null,
  otherPlayers: [],
  habitat: null,
  previousPlayer: null,
  neverScanned: false,
});

const store = {
  state: () => getDefaultState(),
  mutations: {
    RESET_SCAN_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_ANIMAX(state, animax) {
      state.animax = animax;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_HABITAT(state, habitat) {
      state.habitat = habitat;
    },
    SET_NEVER_SCANNED(state, status) {
      state.neverScanned = status;
    },
    SET_OTHER_PLAYERS(state, players) {
      state.otherPlayers = players;
    },
    SET_PREVIOUS_PLAYER(state, previousPlayer) {
      state.previousPlayer = previousPlayer;
    },
  },
  actions: {
    fetchToken({ commit, dispatch }, id) {
      const docRef = doc(db, "tokens", id);

      return onSnapshot(docRef, async (doc) => {
        const token = { id, ...doc.data() };

        await dispatch("fetchOtherPlayers", id);
        commit("SET_TOKEN", token);
      });
    },
    async fetchOtherPlayers({ commit, _, rootState, dispatch }, tokenId) {
      const scansRef = collection(db, "scans");
      const q = query(
        scansRef,
        where("tid", "==", tokenId),
        where("uid", "!=", rootState.currentUser.uid),
        orderBy("uid", "asc"),
        orderBy("date", "desc"),
        limit(3)
      );
      const querySnapshot = await getDocs(q);
      const otherPlayers = [];
      querySnapshot.forEach((doc) => {
        otherPlayers.push(doc.data());
      });
      commit("SET_OTHER_PLAYERS", otherPlayers);
      commit("SET_NEVER_SCANNED", !otherPlayers.length);
      await dispatch("fetchPreviousPlayer");
    },
    async fetchPreviousPlayer({ commit, state }) {
      if (state.previousPlayerName) {
        return state.previousPlayerName;
      }
      if (state.otherPlayers[0]) {
        const previousPlayer = await fetchPlayerInfo(state.otherPlayers[0].uid);
        commit("SET_PREVIOUS_PLAYER", previousPlayer);
      }
    },
  },
  getters: {
    hasAnimaxInCollection: (state, _, rootState) => {
      return !!rootState.player.animals[state.animax.id];
    },
    hasHabitatInCollection: (state, _, rootState) => {
      return !!rootState.player.habitats[state.habitat.id];
    },
  },
};

export default store;
