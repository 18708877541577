const getDefaultState = () => ({
  colors: {
    primary: "white",
    secondary: "white",
  },
});

const store = {
  state: () => getDefaultState(),
  mutations: {
    SET_COLORS(state, colors) {
      state.colors = colors;
    },
  },
  actions: {},
  getters: {},
};

export default store;
