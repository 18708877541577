export const COLUMNS = 7;
export const ROWS = 9;
export const INITIAL_POLLUTED_ROWS = 5;
export const TYPE_EMPTY = "empty";
export const TYPE_TOXIBEAST = "toxibeast";
export const TYPE_PLANT = "plant";
export const TYPE_CHEST = "chest";
export const TYPE_POLLUTED = "polluted";
export const MAX_LEVEL = 10;
export const DEFAULT_DAMAGE = 50;
export const MAX_LIVES = 3;

export const getRandomNumber = (max = 6) => {
  return Math.floor(Math.random() * max);
};

export const selectRandomPositions = (length, n = 1, increment = 0) => {
  const arr = Array.from(Array(length).keys(), (x) => x + increment);
  let m = arr.length;
  while (m) {
    const i = Math.floor(Math.random() * m--);
    [arr[m], arr[i]] = [arr[i], arr[m]];
  }
  return arr.slice(0, n);
};

export const getIndex = (i, j) => {
  if (i < 0 || j < 0) {
    return -1;
  }
  return i * COLUMNS + j;
};

export const getCoordinates = (index) => {
  const i = Math.floor(index / COLUMNS);
  const j = index % COLUMNS;
  return [i, j];
};

export const getRandomSquare = () => {
  return getRandomNumber(ROWS * COLUMNS);
};

export const debounce = (callback, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, timeout);
  };
};

export const getPollution = (row, level) => {
  return Math.pow(2, 7 - row) * (4 + level);
};

export const getElementFromPoint = (event) => {
  const elements = document.elementsFromPoint(
    event.changedTouches[0].clientX,
    event.changedTouches[0].clientY
  );
  const square = elements.find((el) => el.classList.contains("merge-game__square"));
  return parseInt(square?.id);
};

export const getCenterCoordinatesOfSquare = (squareNumber) => {
  const squareElement = document.getElementById(squareNumber.toString());
  if (!squareElement) {
    return null;
  }
  const rect = squareElement.getBoundingClientRect();
  return {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
};
