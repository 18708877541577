import { RouterView } from "vue-router";

const routes = [
  {
    path: "habitats",
    name: "habitats-route",
    component: RouterView,
    redirect: { name: "habitats-list-route" },
    children: [
      {
        path: "",
        name: "habitats-list-route",
        component: () => import("./views/Habitats.vue"),
        meta: { title: "Habitats" },
      },
      {
        path: ":id",
        name: "habitat-card-route",
        component: () => import("./views/HabitatCard.vue"),
        props: true,
        meta: { title: "Habitats - " },
      },
    ],
  },
];

export default routes;
